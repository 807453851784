
import { api } from "@/api/api";
import { ApiCourseResponsibleFilter, ApiGetDashboardCourseContractDto } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { DashboardRouteNames } from "@/shared/enums/RouteNames/dashboardRouteNames.enum";
import { formatDateShort, formatLocalizedDateP } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { useRouter } from "@/shared/useHelpers";
import { PropType, defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  components: { BaseLayout, BaseTableFiltered },
  name: "ContractsPage",
  props: {
    groupBy: String as PropType<string | null>,
  },
  setup() {
    const router = useRouter();
    const faultyContracts = ref<ApiGetDashboardCourseContractDto[]>([]);
    const navigateToResource = (courseId: string, resourceId: string) => {
      router.push(`/kurset/${courseId}/resources/${resourceId}`);
    };

    const dashboardLink = () => useNavigateBack(DashboardRouteNames.Home);

    const getFailedAndExpiredContracts = async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        faultyContracts.value = (
          await api.dashboard.getUnsignedFailedAndExpiredCourseContracts({
            courseResponsibleFilter: ApiCourseResponsibleFilter.SUPERVISOR_AND_LECTURER,
          })
        ).data;
      });
    };

    onMounted(async () => {
      await getFailedAndExpiredContracts();
    });

    return {
      headers,
      formatLocalizedDateP,
      faultyContracts,
      dashboardLink,
      navigateToResource,
      formatDateShort,
    };
  },
});

const headers = [
  {
    text: "Id",
    value: "courseId",
  },
  {
    text: "Handlinger",
    value: "actions",
  },
  {
    text: "Intern tittel",
    value: "courseName",
  },
  {
    text: "Ressursnavn",
    value: "userFullName",
  },
  {
    text: "Kontrakt Id",
    value: "courseContractId",
  },
  {
    text: "Kontraktsstatus",
    value: "courseContractStatus",
  },
  {
    text: "Opprettelse dato",
    value: "inserted",
  },
];
